<template>
  <div class="sheet-status">
    <v-btn v-if="loading" loading class="button fab" small fab />

    <v-dialog
      v-model="dialog"
      width="500"
      v-else-if="sheet && sheet.warnings.length"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="fab">
          <v-badge
            fab
            color="warning"
            :content="sheet.warnings.length"
            overlap
            offset-x="15"
            offset-y="15"
          >
            <v-btn
              :loading="loading"
              class="button"
              small
              v-bind="attrs"
              v-on="on"
              fab
            >
              <v-icon>mdi-alert</v-icon>
            </v-btn>
          </v-badge>
        </div>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline pb-2">Warnings</span>
        </v-card-title>

        <v-list class="pa-0">
          <v-list-item
            class="pl-6"
            two-line
            v-for="(warning, warningIndex) in sheet.warnings"
            :key="warningIndex"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-body-1"
                v-html="getTitle(warning)"
              />
              <v-list-item-subtitle
                class="details text-body-2"
                v-html="getDetails(warning)"
              />
            </v-list-item-content>
            <v-list-item-action v-if="isEditor">
              <v-btn text @click="showWarning(warning)">
                {{ warning.log ? 'LOG' : 'SELECT' }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { manifest } from '../../../../jelly-sheet/src/settings/SheetSettings'

const allSymbols = manifest.map((m) => m.symbol).join(',')

export default {
  name: 'SheetStatus',
  props: { sheet: Object, rendering: Boolean },
  data: () => ({
    dialog: null,
    loading: false,
    labels: {
      'unknown-content': {
        title: () => 'Unknown content',
        details: () => 'The line has a context but no content'
      },
      'unknown-context': {
        title: () => 'Unknown context',
        details: (w) =>
          `'${w.context}' is not valid context. Authorized context are : ${allSymbols}`
      },
      'floating-without-content': {
        title: () => 'Orphean floating line',
        details: (w) =>
          `A floating line need a line to be built. None has been found for '${w.raw}'`
      },
      'empty-division': {
        title: () => 'Empty division',
        details: () => 'A division need a least two slot'
      },
      'content-match': {
        title: () => 'Wrong content format',
        details: (w) => `'${w.raw}' is not a valid slot for this context.`
      },
      'wrong-division-shortcut': {
        title: () => 'Wrong division shortcut',
        details: (w) =>
          `'${w.value}' is not a valid division shortcut. That must be a integer greater than 1.`
      },
      'metric-mismatch': {
        title: () => 'Metric mismatch',
        details: (w) =>
          `All divisions at the same index must have the same length. The maximum length of divisions at index ${w.index} is ${w.max}, but the division of the context ${w.context} is ${w.current}`
      },
      'division-mismatch': {
        title: () => 'Division mismatch',
        details: (w) =>
          `Each flow must have the same length. There are ${w.missing} missing division in the flow ${w.context}.`
      },
      'metric-overflow': {
        title: () => 'Metric overflow',
        details: (w) =>
          `The length of a division cannot exceed 12 except if the score contains only one division (no metric). Here we have ${w.count} slots.`
      },
      'unknown-error': {
        title: () => 'Unknown error',
        details: () => `A uncatched error occurs. Hope it's not too bad :)`
      }
    }
  }),
  computed: {
    ...mapGetters('editor', ['editor']),

    isEditor() {
      return this.editor
    }
  },
  watch: {
    rendering(value) {
      if (this._timeoutId) clearTimeout(this._timeoutId)
      if (value) {
        this._timeoutId = setTimeout(() => {
          this.loading = true
        }, 250)
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapActions('editor', ['setSelection']),

    getTitle(warning) {
      const title = this.labels[warning.type].title(warning)
      if (warning.log) {
        return title
      }
      const pos = `at line ${warning.line + 1} char ${warning.char}`
      return `${title} ${pos}`
    },

    getDetails(warning) {
      return this.labels[warning.type].details(warning)
    },

    showWarning(warning) {
      if (warning.log) {
        console.error(warning.log())
      } else {
        this.dialog = false
        this.$nextTick(() => {
          this.setSelection({
            from: { line: warning.line, ch: warning.char },
            to: { line: warning.line, ch: warning.char + warning.length }
          })
        })
      }
    }
  }
}
</script>
<style scoped>
.fab {
  position: absolute;
  top: var(--app-gutter-md);
  right: var(--app-gutter-md);
  z-index: 2;
}
.button {
  background-color: var(--sheet-background-color);
}
.info {
  font-size: smaller;
}
.details {
  white-space: initial;
  /* text-align: justify; */
}
</style>
