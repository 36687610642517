import isEqual from 'lodash/isEqual'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: [Object, String, Number, Boolean, Array],
    items: Array,
    multiple: Boolean
  },
  data: () => ({
    // Array of Index if multiple === true if no Index
    selection: [],
    valueOut: []
  }),
  watch: {
    value: {
      handler(value) {
        if (value === null || value === undefined) {
          this.selection = this.multiple ? [] : -1
          return
        }
        if (this.multiple) {
          //
          // MULTIPLE
          //
          if (!Array.isArray(value)) {
            throw new Error(
              'Selection item must be a array if multiple is true'
            )
          }
          const newSelection = []
          this.valueOut.length = 0
          value.forEach((v) => {
            const index = this.items.findIndex((i) => i.value === v)
            if (index > -1) newSelection.push(index)
            else this.valueOut.push(v)
          })
          if (!isEqual(this.selection, newSelection)) {
            this.selection = newSelection
          }
        } else {
          //
          // SINGLE
          //
          if (Array.isArray(value)) {
            throw new Error(
              'Selection item cannot be a array if multiple is false'
            )
          }
          this.selection = this.items.findIndex((item) =>
            isEqual(item.value || item, value)
          )
        }
      },
      immediate: true
    }
  },
  methods: {
    updateValue(selection) {
      if (selection === null || selection === undefined) return
      if (this.multiple) {
        const newValue = selection.map((index) => this.items[index].value)
        if (!isEqual(this.value, newValue)) {
          this.$emit('change', [...this.valueOut, ...newValue])
        }
      } else {
        const selected = this.items[selection]
        this.$emit('change', selected.value || selected)
      }
    }
  }
}

// import isEqual from 'lodash/isEqual'

// export default {
//   model: {
//     prop: 'value',
//     event: 'change'
//   },
//   props: {
//     value: [Object, String, Number, Boolean],
//     items: Array,
//     multiple:Boolean
//   },
//   data: () => ({
//     selectedIndex: 0
//   }),
//   watch: {
//     value: {
//       handler(value) {
//         this.selectedIndex = this.items.findIndex((item) =>
//           isEqual(item.value, value)
//         )
//         this.selected = this.items[this.selectedIndex] || {}
//       },
//       immediate: true
//     }
//   },
//   methods: {
//     updateValue(newValue) {
//       this.$emit('change', this.items[newValue])
//     }
//   }
// }
