<template>
  <fluid-bar class="player-bar" ref="fluidBar" :landscape="landscape">
    <bar-button
      class="divider after"
      icon="monitor-edit"
      v-show="!isEditor && !isPeer"
      :to="playerLink"
    />

    <!-- PLAY -->
    <bar-button
      v-show="isEditor"
      :icon="playButtonIcon"
      @click="$emit('toggle-play')"
      :loading="loading"
    />

    <!-- RENDER -->
    <bar-button :icon="renderIcon">
      <render-menu :landscape="landscape" />
    </bar-button>

    <!-- RENDER OPTIONS-->
    <bar-button icon="render-options">
      <render-options-menu :landscape="landscape" />
    </bar-button>

    <!-- HIGHTLIGHT -->
    <bar-button icon="score-select" v-show="!isEditor">
      <highlight-menu :landscape="landscape" />
    </bar-button>

    <!-- LAYOUT -->
    <bar-button icon="layout" v-show="!isLyric && !isCompact">
      <layout-menu :landscape="landscape" />
    </bar-button>

    <!-- TEMPO -->
    <bar-button class="divider before" icon="metronome" v-show="!isPeer">
      <tempo-menu :landscape="landscape" />
    </bar-button>

    <!-- TRANSPOSE -->
    <bar-button icon="transpose" v-show="!isPeer">
      <transpose-menu :landscape="landscape" />
    </bar-button>

    <!-- SOUND -->
    <bar-button v-show="showSound" icon="score-speaker">
      <synth-menu :landscape="landscape" />
    </bar-button>

    <!-- EXTENDS -->

    <bar-button class="divider before" icon="screen-layout">
      <screen-layout-menu :landscape="landscape" />
    </bar-button>

    <bar-button
      v-show="!isEditor"
      @click="toggleExtends"
      icon="score-extends"
    />
  </fluid-bar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

import FluidBar from './FluidBar.vue'
import BarButton from '../button/BarButton.vue'
import HighlightMenu from '../menu/HighlightMenu.vue'
import LayoutMenu from '../menu/LayoutMenu.vue'
import RenderMenu from '../menu/RenderMenu.vue'
import RenderOptionsMenu from '../menu/RenderOptionsMenu.vue'
import ScreenLayoutMenu from '../menu/ScreenLayoutMenu.vue'
import SynthMenu from '../menu/SynthMenu.vue'
import TempoMenu from '../menu/TempoMenu.vue'
import TransposeMenu from '../menu/TransposeMenu.vue'

export default {
  name: 'PlayerBar',
  components: {
    BarButton,
    FluidBar,
    HighlightMenu,
    LayoutMenu,
    RenderMenu,
    RenderOptionsMenu,
    ScreenLayoutMenu,
    SynthMenu,
    TempoMenu,
    TransposeMenu
  },
  props: {
    landscape: Boolean,
    layout: String
  },
  data: () => ({
    layouts: [
      { label: 'Auto', icon: 'render-auto' },
      { label: 'Lyrics', icon: 'render-lyric' },
      { label: 'Chart', icon: 'render-chart' },
      { label: 'Staff', icon: 'render-staff' }
    ]
  }),
  watch: {
    isPeer() {
      this.$refs.fluidBar.forceUpdate()
    },
    isCompact() {
      this.$refs.fluidBar.forceUpdate()
    },
    isLyric() {
      this.$refs.fluidBar.forceUpdate()
    },
    isStaff() {
      this.$refs.fluidBar.forceUpdate()
    },
    isEditor() {
      this.$refs.fluidBar.forceUpdate()
    }
  },
  computed: {
    ...mapGetters('score', ['renderer', 'report']),
    ...mapGetters('player', ['playing', 'loading']),
    ...mapGetters('editor', ['isSplitted']),

    playButtonIcon() {
      return this.playing ? 'pause' : 'play'
    },

    renderIcon() {
      if (this.isLyric) return 'score-display-lyric'
      if (this.isStaff) return 'score-display'
      else return 'score-display-chart'
    },

    isCompact() {
      const breakpoint = this.$vuetify.breakpoint
      return !this.isSplitted ? breakpoint.xsOnly : breakpoint.smAndDown
    },

    isEditor() {
      return this.$route.params.contextId === 'editor'
    },
    isLyric() {
      return this.renderer === 'lyric'
    },
    isStaff() {
      return this.renderer === 'staff'
    },
    hasMetric() {
      return this.report?.hasMetric
    },
    isPeer() {
      return this.$route.name?.startsWith('peer')
    },
    showSound() {
      return this.isStaff && !this.isPeer
    },

    playerLink() {
      const driveId = this.$route.params.driveId
      const scoreId = this.$route.params.scoreId
      return `/score/editor/${driveId}/${scoreId}`
    }
  },
  methods: {
    ...mapMutations('score', ['toggleExtends'])
  }
}
</script>
<style scoped></style>
