<template>
  <bar-menu :landscape="landscape">
    <div class="pad">
      <tile-button
        icon="chevron-double-left"
        class="divider"
        @click="update(interval.prev)"
      />
      <tile-button icon="chevron-left" class="divider" @click="update(-1)" />
      <tile-button icon="restore" class="divider before" @click="update()" />
      <tile-button
        icon="chevron-right"
        class="divider before"
        @click="update(1)"
      />
      <tile-button
        icon="chevron-double-right"
        class="divider before"
        @click="update(interval.next)"
      />
    </div>
    <div class="details">
      <div :class="offsetClass">
        <span>{{ offset }}</span>
        <span class="unit" v-if="transpose">{{ unit }}</span>
      </div>
      <div class="interval" v-if="transpose">
        {{ interval.label }}
      </div>
    </div>
  </bar-menu>
</template>

<script>
import { mapGetters } from 'vuex'

import menuMixin from '../../mixins/menu-mixin'
import debounce from 'lodash/debounce'

import BarMenu from '../menu/BarMenu.vue'
import TileButton from '../button/TileButton.vue'

export default {
  name: 'TransposeMenu',
  mixins: [menuMixin],
  components: {
    TileButton,
    BarMenu
  },
  data: () => ({
    transpose: 0,
    intervals: [
      { id: '0', label: 'Perfect unisson', next: 2, prev: -1 },
      { id: '1', label: 'Minor second', next: 1, prev: -1 },
      { id: '2', label: 'Major second', next: 2, prev: -2 },
      { id: '3', label: 'Minor third', next: 1, prev: -1 },
      { id: '4', label: 'Major third', next: 1, prev: -2 },
      { id: '5', label: 'Perfect four', next: 2, prev: -1 },
      { id: '6', label: 'Tritone', next: 2, prev: -1 },
      { id: '7', label: 'Perfect fifth', next: 2, prev: -2 },
      { id: '8', label: 'Minor sixth', next: 1, prev: -1 },
      { id: '9', label: 'Major sixth', next: 2, prev: -2 },
      { id: '10', label: 'Minor seventh', next: 1, prev: -1 },
      { id: '11', label: 'Major seventh', next: 1, prev: -2 }
    ],
    degrees: [2, 2, 3, 3, 5, 2, 3, 4, 4, 5, 5, 6]
  }),
  computed: {
    ...mapGetters('score', ['scoreSettings']),

    offsetClass() {
      const cls = ['offset']
      if (this.transpose === 0) cls.push('light')
      return cls
    },

    offset() {
      let t = this.transpose
      if (!t) {
        return 'No transposition'
      }
      if (Math.abs(t) < 2) {
        t = t > 0 ? '½' : '-½'
      } else if (t % 2) {
        t = t > 1 ? `${Math.floor(t / 2)}½` : `${Math.floor(t / 2) + 1}½`
      } else {
        t = `${t / 2}`
      }
      return this.transpose > 0 ? `+${t}` : String(t)
    },

    unit() {
      return this.transpose > 1 ? 'tones' : 'tone'
    },

    interval() {
      const transpose = this.transpose % 12
      if (transpose < 0) {
        return this.intervals[transpose + 12]
      } else {
        return this.intervals[transpose]
      }
    }
  },
  watch: {
    'scoreSettings.transpose'(value) {
      this.transpose = value
    }
  },
  mounted() {
    this.debouncedUpdate = debounce(() => {
      this.setScoreSettings(['transpose', this.transpose])
    }, 250)
    this.transpose = this.scoreSettings?.transpose || 0
  },
  methods: {
    update(val) {
      if (!isNaN(val)) {
        this.transpose += val
      } else {
        this.transpose = 0
      }
      this.debouncedUpdate()
    }
  }
}
</script>
<style scoped>
.pad {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--app-color-line);
}

.details {
  min-height: 96px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.offset {
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  display: flex;
}
.offset.light {
  font-weight: initial;
  font-size: large;
}
.offset .unit {
  /* font-size: medium; */
  margin-left: var(--app-gutter-xs);
  /* margin-top: -8px; */
}
.interval {
  /* min-height: 48px; */
  font-size: x-large;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
