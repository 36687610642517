<template>
  <div :class="layoutClass" v-resize="update">
    <div class="bar">
      <slot name="bar" v-bind:landscape="isLandscape" v-bind:layout="layout" />
    </div>

    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  name: 'FluidLayout',
  props: {
    noBar: Boolean,
    watch: Boolean,
    extended: Boolean,
    locked: Boolean
  },
  data: () => ({
    isLandscape: true,
    layout: 'vertical'
  }),
  computed: {
    layoutClass() {
      const cls = ['fluid-layout', this.layout]
      if (this.noBar) cls.push('no-bar')
      if (this.extended) cls.push('extended')
      return cls
    }
  },
  watch: {
    watch() {
      this.update()
    }
  },
  created() {
    this.update = debounce(this.debouncedUpdate, 10, { leading: true })
  },
  mounted() {
    this.update()
  },
  methods: {
    debouncedUpdate() {
      if (this.locked) {
        this.isLandscape = false
      } else {
        const width = this.$el.clientWidth
        const height = this.$el.clientHeight
        const isLandscape = width > height
        this.isLandscape = isLandscape
      }
      this.layout = this.isLandscape ? 'vertical' : 'horizontal'
    }
  }
}
</script>
<style scoped>
.fluid-layout {
  --app-bar-size: 48px;
  width: 100%;
  height: 100%;
}
.fluid-layout.extended {
  --app-bar-size: 97px;
}
/* BAR */
.no-bar .bar {
  opacity: 0;
  pointer-events: none;
}
.bar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}
.fluid-layout.horizontal .bar {
  border-bottom: solid 1px var(--app-color-line);
  height: calc(var(--app-bar-size) + 1px);
  right: 0;
}
.fluid-layout.vertical .bar {
  border-right: solid 1px var(--app-color-line);
  width: calc(var(--app-bar-size) + 1px);
  bottom: 0;
}
/* CONTENT */
.content {
  position: absolute;
  right: 0;
  bottom: 0;
}
.fluid-layout.horizontal .content {
  top: var(--app-bar-size);
  left: 0;
}
.fluid-layout.vertical .content {
  left: var(--app-bar-size);
  top: 0;
}
.fluid-layout.no-bar .content {
  top: 0;
  left: 0;
}
</style>
