<template>
  <!-- BREADCRUMB -->

  <div class="app-breadcrumb">
    <div ref="measurer" class="name measurer" />
    <tile-button icon="folder-home-outline" class="divider mr-2" to="/drive" />
    <div
      class="item"
      v-for="(item, index) in items"
      :key="index"
      :style="item.style"
      v-on:dragover="dragover(item, $event)"
      v-on:dragleave="dragleave(item, $event)"
      v-on:drop="dragdrop(item, $event)"
      v-on:dragend="dragend(item, $event)"
    >
      <v-btn exact text :to="item.to" v-if="!item.isCurrent"
        ><span class="mr-2 name">{{ item.name }}</span>
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>

      <v-menu v-model="menuOpened" offset-y v-else>
        <template v-slot:activator="{ attrs }">
          <v-btn class="pr-1" text v-bind="attrs" @click="openMenu()">
            <span class="name">{{ item.name }}</span>
            <v-icon class="pl-1">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <component :is="item.type" :item="item" @subclick="close()" />
      </v-menu>
    </div>
    <v-spacer />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import driveDragMixin from '../../mixins/driveDragMixin'
import TileButton from '../button/TileButton.vue'
export default {
  name: 'AppBreadcrumb',
  mixins: [driveDragMixin],
  components: {
    drive: () => import('../menu/DriveMenu.vue'),
    score: () => import('../menu/ScoreMenu.vue'),
    TileButton
  },
  data: () => ({
    menuOpened: false,
    roots: [
      { name: 'Local storage', id: 'local', to: '/drive/local' },
      { name: 'Remote', id: 'remote', to: '/drive/remote' }
    ]
  }),
  computed: {
    ...mapGetters('drive', ['current', 'breadcrumbs']),
    ...mapGetters('score', ['score']),

    items() {
      if (!this.breadcrumbs) return []
      const result = []
      const driveId = this.$route.params.driveId
      this.breadcrumbs.map((breadItem, breadItemIndex) => {
        const isLast = breadItemIndex === this.breadcrumbs.length - 1
        let item = {
          type: breadItem.isFolder ? 'drive' : 'score',
          isCurrent: isLast
        }
        if (breadItemIndex === 0) {
          const root = this.roots.find((i) => i.id === breadItem.id)
          item = Object.assign(item, root)
        } else {
          item = Object.assign(item, {
            to: `/drive/${driveId}/${breadItem.id}`,
            name: breadItem.name,
            id: breadItem.id,
            meta: breadItem.meta
          })
        }
        result.push(item)
      })
      return result
    }
  },
  methods: {
    ...mapMutations('app', ['setContextMenu']),

    measure(val) {
      const measurerEl = this.$refs.measurer
      if (!measurerEl) return
      measurerEl.innerText = val
      return measurerEl.getBoundingClientRect().width
    },
    openMenu() {
      this.menuOpened = true
      this.setContextMenu(this.closeMenu)
    },
    closeMenu() {
      if (!this.menuOpened) return
      this.menuOpened = false
      this.setContextMenu()
    }
  }
}
</script>

<style scoped>
.app-breadcrumb {
  display: inline-flex;
  width: 100%;
  padding-right: var(--app-gutter-sm);
  min-height: 48px;
  border-bottom: solid 1px var(--app-color-line);
  align-items: center;
}
.item {
  display: inline-block;
  min-width: 0;
}
.v-btn {
  text-transform: initial;
  padding: var(--app-gutter-xs) !important;
  min-width: initial !important;
  font-weight: initial;
  width: 100%;
}
.v-btn--active::before {
  opacity: 0 !important;
}
.v-btn /deep/ .v-btn__content {
  width: 100%;
}
.name {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* padding: 0 var(--app-gutter-sm); */
}
.measurer {
  position: fixed;
  display: inline;
  height: 0;
  width: auto;
}
</style>
