<template>
  <div class="metronome" v-if="isVisible">
    <svg viewBox="0 0 200 150">
      <g class="container">
        <path
          class="background"
          d="M50.25,3.4c-1.14-4.75-27.61-4.32-28.58,0C13.22,47.17-4.12,147.19,1,148.82c5.52,1.5,64.82,1.83,70-.57C76,146.25,59.47,48.08,50.25,3.4ZM61,122.94a344,344,0,0,1-50,0c-1.45,0,9.64-79,15.52-114.84.68-3.36,18.26-3.45,18.88,0C51.46,44.85,62.4,122.94,61,122.94Z"
        />
        <path
          class="hole"
          d="M60.9,122.9c-16.6,1.2-33.4,1.2-50,0c-1.4,0,9.6-79,15.5-114.8c0.7-3.4,18.3-3.4,18.9,0C51.4,44.8,62.3,122.9,60.9,122.9z"
        />
        <g class="center">
          <g class="pendulum" :style="pendulumStyle">
            <rect class="stick" />
            <rect class="weight" :style="weightStyle" />
          </g>
          <circle class="center-cache" r="4" />
          <text class="tempo">{{ tempoLabel }}</text>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Metronome',
  data: () => ({
    currentBeatTime: 0,
    nextBeatTime: 0
  }),
  computed: {
    ...mapGetters('score', ['report']),
    ...mapGetters('player', ['progress', 'headEvent', 'playing', 'tempoRatio']),
    ...mapGetters('metronome', ['display']),

    isVisible() {
      return this.headEvent && this.display != 'none'
    },

    tickpos() {
      if (!this.playing) return 0
      const progress = this.progress
      const start = this.currentBeatTime
      const end = this.nextBeatTime
      let value = ((progress - start) / (end - start)) * 2 - 1
      const beatIsOdd = this.headEvent.beatIndex % 2 === 0
      if (beatIsOdd) {
        value *= -1
      }
      return value
    },
    tempoValue() {
      const min = 40
      const max = 300
      const value = Math.round((this.headEvent?.tempo || 0) * this.tempoRatio)
      const tempo = Math.min(max, Math.max(min, value))
      return (tempo - min) / (max - min)
    },
    tempoLabel() {
      return Math.round((this.headEvent?.tempo || 0) * this.tempoRatio)
    },
    pendulumStyle() {
      const angleMin = 5
      const angleMax = 60
      const tempoValue = this.tempoValue
      const rotateMax = (1 - tempoValue) * (angleMax - angleMin) + angleMin
      const rotate = 180 - rotateMax * Math.sin(this.tickpos)
      return `transform: rotate(${rotate}deg);`
    },
    weightStyle() {
      const tMin = 10
      const tMax = 90
      const tempoValue = this.tempoValue
      const translate = tempoValue * (tMax - tMin) + tMin
      return `transform: translate(-6px, ${translate}px);`
    }
  },
  watch: {
    headEvent(value) {
      if (!value) {
        this.currentBeatTime = 0
        this.nextBeatTime = 0
      } else {
        const events = this.report.events()
        let headEventIndex = value.index
        let currentBeatEvent = value
        while (currentBeatEvent && !currentBeatEvent.onBeat) {
          headEventIndex--
          currentBeatEvent = events[headEventIndex]
        }
        let position = currentBeatEvent?.position.time || 0
        this.currentBeatTime = Math.round(position / this.tempoRatio)
        //
        //
        //
        let nextEventIndex = value.index + 1
        let nextBeatEvent = events[nextEventIndex]
        while (nextBeatEvent && !nextBeatEvent.onBeat) {
          nextEventIndex++
          nextBeatEvent = events[nextEventIndex]
        }
        position = nextBeatEvent?.position.time || 0
        this.nextBeatTime = Math.round(position / this.tempoRatio)
      }
    }
  }
}
</script>

<style scoped>
.metronome {
  position: absolute;
  bottom: 10px;
  left: -44px;
  width: 200px;
  height: 150px;
  z-index: 2;
}
.container {
  transform: translate(64px);
}
.background {
  fill: var(--v-primary-lighten3);
}
.hole {
  fill: var(--app-color-paper);
  opacity: 0.9;
}
.center {
  transform: translate(36px, 125px);
}
.center-cache {
  fill: var(--v-primary-lighten3);
}
.pendulum {
  transform: rotate(180deg);
}
.stick {
  fill: var(--v-primary-lighten2);
  width: 4px;
  height: 110px;
  transform: translate(-2px);
}
.weight {
  fill: var(--v-primary-lighten2);
  width: 12px;
  height: 12px;
}
.tempo {
  fill: white;
  text-anchor: middle;
  transform: translateY(17px);
  font-size: 14px;
}
</style>
