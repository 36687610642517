<template>
  <div :class="bubblePopupClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BubblePopup',
  props: {
    arrowCenter: Boolean,
    left: Boolean
  },
  computed: {
    bubblePopupClass() {
      const result = ['bubble-popup']
      if (this.arrowCenter) result.push('centered')
      result.push(this.left ? 'left' : 'right')
      return result
    }
  }
}
</script>

<style scoped>
.bubble-popup {
  background-color: var(--app-color-list) !important;
  border: solid 1px var(--app-color-line);
  box-shadow: var(--app-box-shadow);
}
/* .bubble-popup.right {
  margin-left: 16px;
}
.bubble-popup.left {
  margin-right: 16px;
}

.bubble-popup:after {
  content: '';
  position: absolute;
  top: 22px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background: var(--app-color-list);
  border-top: solid 1px var(--app-color-line);
}
.bubble-popup.right:after {
  left: 10px;
  border-left: solid 1px var(--app-color-line);
}
.bubble-popup.left:after {
  right: 10px;
  border-right: solid 1px var(--app-color-line);
} */

.bubble-popup.centered:after {
  top: calc(50% - 18px);
}
</style>
