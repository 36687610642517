<template>
  <bar-menu :landscape="landscape">
    <!-- <switch-menu-item label="Sound enabled" v-model="synth" /> -->
    <!-- <synth v-if="synth" :loaded.sync="synthLoaded"></synth> -->
    <slider-menu-item
      :icon-trigger="muteVolume"
      icon="volume-high"
      :value="settings.synth.volume"
      @change="setSettings(['synth/volume', $event])"
      :max="100"
    />
  </bar-menu>
</template>

<script>
import menuMixin from '../../mixins/menu-mixin'

import BarMenu from '../menu/BarMenu.vue'
import SliderMenuItem from '../menu-item/SliderMenuItem.vue'

export default {
  name: 'SynthMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SliderMenuItem
  },
  data: () => ({
    synth: false,
    soundOn: false,
    synthLoaded: false
  }),
  methods: {
    muteVolume() {
      this.setSettings(['synth/volume', 0])
    }
  }
}
</script>
<style scoped></style>
