<template>
  <fluid-layout class="player" :watch="isEditor" :no-bar="scoreExtends">
    <!-- BAR -->
    <template v-slot:bar="{ landscape, layout }">
      <player-bar
        :landscape="landscape"
        :layout="layout"
        @toggle-play="onTogglePlay($event)"
      />
    </template>
    <!-- CONTENT -->
    <template v-slot:content>
      <div
        @mousedown="onMouseDown($event)"
        @mouseup="onMouseUp($event)"
        class="viewer-wrapper fill-height"
      >
        <viewer
          ref="viewer"
          :source="score.draft"
          :options="sheetOptions"
          :head="head"
          :selection="selection"
          :locked="locked"
          :report.sync="report"
          :rendering.sync="rendering"
          :compact="isCompact"
          :progress="sheetProgress"
          :scroll-trigger="
            report && report.hasMetric ? 'selection' : 'progress'
          "
          :lock-size="scoreExtends"
          verbose
          @user-select="onUserSelect($event)"
          @scroll="$emit('scroll', $event)"
        >
          <template v-slot:header>
            <sheet-header v-if="showHeader" />
          </template>
          <template v-slot:footer>
            <sheet-footer v-if="showHeader" />
          </template>
          <template v-slot:empty>
            <div class="empty-message">Nothing to display</div>
            <div class="detail-message">{{ detailsMessage }}</div>
          </template>
        </viewer>
      </div>
      <!-- METRONOME -->
      <metronome ref="metronome" v-if="showMetronome" />

      <sheet-status :sheet="report" :rendering="rendering" />

      <!-- PLAY BUTTON -->
      <play-button
        v-if="!isEditor && !isPeer"
        @toggle-play="onTogglePlay($event)"
      />
    </template>
  </fluid-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import Viewer from '../../../../jelly-sheet/src/components/view/Viewer.vue'

import FluidLayout from '../layout/FluidLayout.vue'
import PlayerBar from '../toolbar/PlayerBar.vue'
import SheetHeader from './SheetHeader.vue'
import SheetFooter from './SheetFooter.vue'
import SheetStatus from './SheetStatus.vue'
import Metronome from './Metronome.vue'
import PlayButton from './PlayButton.vue'

export default {
  name: 'Player',
  components: {
    FluidLayout,
    PlayerBar,
    Viewer,
    SheetStatus,
    SheetHeader,
    SheetFooter,
    Metronome,
    PlayButton
  },
  props: {
    locked: Boolean
  },
  data: () => ({
    report: null,
    rendering: false
  }),
  computed: {
    ...mapGetters('score', [
      'score',
      'scoreSettings',
      'countdown',
      'scoreExtends'
    ]),
    ...mapGetters('player', [
      'progress',
      'head',
      'selection',
      'playing',
      'delaying',
      'loading',
      'tempoRatio'
    ]),
    ...mapGetters('editor', ['isSplitted']),

    totalProgress() {
      if (!this.report) return 0
      const report = this.report
      return Math.round(report.duration.time / this.tempoRatio)
    },

    showHeader() {
      return this.scoreSettings.renderOptions.indexOf('header') > -1
    },

    sheetOptions() {
      //
      // Return a array which encapsulate all settings from stores. This is a
      // good place to override settings
      //
      const s = { ...this.scoreSettings }
      const breakpoint = this.$vuetify.breakpoint
      const isCompact = !this.isSplitted
        ? breakpoint.xsOnly
        : breakpoint.smAndDown
      const isChart = s.renderer === 'chart'

      if (isCompact) {
        s.layout = 'wrap'
        s.spacing = 'dense'
      }

      if (isChart && s.layout === 'breakline') {
        s.layout = 'wrap'
      }

      const result = []
      for (let name in s) {
        const value = s[name]
        const path = 'user.' + name
        result.push({ name, path, value })
      }
      return result
    },

    isEditor() {
      return this.$route.params.contextId === 'editor'
    },

    isCompact() {
      return this.$vuetify.breakpoint.smAndDown
    },

    isPeer() {
      return this.$route.name?.startsWith('peer')
    },

    showMetronome() {
      return !this.isEditor && !this.isPeer && this.report?.hasMetric
    },

    sheetProgress() {
      if (!this.report || this.report.hasMetric) return 0
      const max = Math.round(this.report.duration.time / this.tempoRatio)
      const current = this.progress
      return current / max
    },

    detailsMessage() {
      const report = this.report
      if (!report) return ''
      const details = []
      switch (this.scoreSettings.renderer) {
        case 'lyric':
          if (!report.hasLyric) details.push('no lyric')
          if (!report.hasHarmony) details.push('no harmony')
          break
        case 'chart':
          if (!report.hasMetric) details.push('no metric')
          if (!report.hasHarmony) details.push('no harmony')
          break
        case 'staff':
          if (!report.hasMetric) details.push('no metric')
          if (!report.hasNote) details.push('no note')
          break
      }
      if (details.length) {
        return details[0].charAt(0).toUpperCase() + details.join(', ').slice(1)
      } else {
        return ''
      }
    }
  },
  watch: {
    report(value) {
      this.setReport(value)
    },
    scoreExtends() {
      setTimeout(this.$refs.viewer.updateSelection, 250)
    }
  },
  destroyed() {
    if (this._isDown) {
      window.removeEventListener('mouseup', this.onMouseUp)
    }
    this.destroy()
  },
  methods: {
    ...mapMutations('player', ['destroy']),
    ...mapActions('player', ['goto', 'play', 'playWithDelay', 'stop']),
    ...mapActions('score', ['setReport']),

    async onUserSelect(args) {
      //
      // Update player head
      //
      const progress = Math.round(args.event.position.time / this.tempoRatio)
      this.goto({ progress })
      //
      // Emit to score view to allow code selection
      //
      this.$emit('user-select', args)
    },

    onTogglePlay() {
      if (this.loading) return
      if (this.playing || this.delaying) {
        this.stop()
      } else {
        if (this.countdown && this.report.hasMetric) {
          this.playWithDelay(this.countdown)
        } else {
          this.play()
        }
      }
    },

    onMouseDown() {
      if (this.playing) {
        window.addEventListener('mouseup', this.onMouseUp)
        this._isDown = true
        this.stop()
      }
    },

    async onMouseUp() {
      if (this._isDown) {
        window.removeEventListener('mouseup', this.onMouseUp)
        if (!this.report?.hasMetric) {
          const viewerEl = this.$refs.viewer.$el
          const diffRatio =
            viewerEl.scrollTop /
            (viewerEl.scrollHeight - viewerEl.clientHeight / 2)
          const progress = this.totalProgress * diffRatio
          await this.goto({ progress })
        }
        await this.play()
        this._isDown = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/common.scss';
.player {
  width: 100%;
  height: 100%;
  position: relative;
}
.viewer {
  width: 100%;
  height: 100%;
  // position: relative;
}
.empty-message {
  text-align: center;
  font-size: medium;
}
.detail-message {
  opacity: 0.5;
  font-size: small;
  text-align: center;
}
</style>
