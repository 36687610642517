<template>
  <div class="play-button" :style="btnStyle">
    <v-btn
      x-large
      fab
      color="accent"
      @click="togglePlay()"
      :loading="loading"
      elevation="2"
    >
      <div v-if="delay > 0">{{ delay }}</div>
      <v-icon v-else>{{ playPauseIcon }}</v-icon>
    </v-btn>

    <v-btn
      v-if="scoreExtends"
      icon
      large
      @click="toggleExtends()"
      class="extends-off"
      elevation="2"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-menu
      offset-y
      v-if="scoreExtends"
      content-class="elevation-0"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :style="btnStyle"
          icon
          large
          class="submenu-activator"
          v-bind="attrs"
          v-on="on"
          elevation="2"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <div class="submenu">
        <div class="extends-buttons elevation-2">
          <v-btn large icon @click="transposeClickHandler(1)"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
          <v-icon class="group-icon">mdi-music-accidental-sharp</v-icon>
          <v-btn large icon @click="transposeClickHandler(-1)"
            ><v-icon>mdi-minus</v-icon></v-btn
          >
        </div>
        <div v-if="scoreExtends" class="extends-buttons elevation-2">
          <v-btn large icon @click="tempoClickHandler(0.1)"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
          <v-icon class="group-icon">mdi-metronome</v-icon>
          <v-btn large icon @click="tempoClickHandler(-0.1)"
            ><v-icon>mdi-minus</v-icon></v-btn
          >
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import tempoMixin from '../../mixins/tempo-mixin'
import debounce from 'lodash/debounce'

export default {
  name: 'PlayerButton',
  mixins: [tempoMixin],
  data: () => ({
    autoExtends: false,
    tempoOffset: 0,
    transposeOffset: 0
  }),
  computed: {
    ...mapGetters('player', ['playing', 'delay', 'loading']),
    ...mapGetters('score', ['scoreExtends']),

    playPauseIcon() {
      return this.playing ? 'mdi-pause' : 'mdi-play'
    },

    btnStyle() {
      const right = this.$vuetify.application.right + 16
      const bottom = this.$vuetify.application.bottom
      return `right:${right}px, bottom:${bottom}px`
    }
  },
  watch: {
    playing(value) {
      if (!value) {
        if (this.scoreExtends && this.autoExtends) {
          this.toggleExtends()
        }
        this.autoExtends = false
      }
    }
  },
  mounted() {
    this.debouncedUpdateTempo = debounce(() => {
      const newTempo = this.scoreSettings.tempo + this.tempoOffset
      this.updateTempo(newTempo)
      this.tempoOffset = 0
    }, 250)

    this.debouncedUpdateTranspose = debounce(() => {
      const newTranspose =
        (this.scoreSettings.transpose + this.transposeOffset) % 12
      this.setScoreSettings(['transpose', newTranspose])
      this.transposeOffset = 0
    }, 250)
  },
  methods: {
    ...mapMutations('score', ['toggleExtends']),

    togglePlay() {
      this.$emit('toggle-play')
    },

    tempoClickHandler(offset) {
      this.tempoOffset += offset
      this.debouncedUpdateTempo()
    },

    transposeClickHandler(offset) {
      this.transposeOffset += offset
      this.debouncedUpdateTranspose()
    }
  }
}
</script>
<style scoped>
.play-button {
  position: absolute;
  right: 16px;
  bottom: var(--app-gutter-sm);
  z-index: 6;
}
.submenu-activator {
  position: fixed;
  top: var(--app-gutter-md);
  right: var(--app-gutter-md);
  z-index: 2;
  background: var(--app-color-layout);
}
.submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.extends-buttons {
  display: flex;
  flex-direction: column;
  background: var(--app-color-layout);
  border-radius: 1000px;
  align-items: center;
  margin-top: var(--app-gutter-md);
}
.extends-buttons .group-icon {
  /* opacity: 0.8; */
  color: #444444 !important;
}

.extends-off {
  position: fixed;
  left: var(--app-gutter-md);
  top: var(--app-gutter-md);
  z-index: 2;
  background: var(--app-color-layout);
}
</style>
