<template>
  <div class="score-header">
    <div :class="details.class" v-if="details.length">
      <div
        class="detail"
        v-for="(detail, detailIndex) in details"
        :key="detailIndex"
      >
        <div class="detail-title">{{ detail.title }}</div>
        <div v-for="(n, j) in detail.items" :key="j">{{ n }}</div>
      </div>
    </div>
    <div class="title-container" v-if="title">
      <div class="title" v-if="title">{{ title }}</div>
      <div class="subtitle" v-for="subtitle in subtitles" :key="subtitle.index">
        {{ subtitle.label }}
      </div>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SheetHeader',
  data: () => ({
    metas: {
      title: 'Song title',
      composers: ['Toto'],
      lyricists: ['Tiit'],
      performers: ['Tata']
    },
    labels: ['Music by', 'Lyrics by', 'Performed by']
  }),
  computed: {
    ...mapGetters('drive', ['current']),
    ...mapGetters('score', ['score']),

    scoreMeta() {
      return this.current?.meta || []
    },

    title() {
      return this.scoreMeta.title?.find(() => true) || this.current?.name
    },

    subtitles() {
      let subtitle = []
      if (this.scoreMeta.title?.length) {
        subtitle = this.scoreMeta.title
          .slice(1)
          .map((label, index) => ({ label, index }))
      }
      return subtitle
    },

    details() {
      const scoreMeta = this.scoreMeta
      if (!scoreMeta) return

      const details = []
      const metaDetails = [
        scoreMeta.composer,
        scoreMeta.lyricist,
        scoreMeta.performer
      ]
      metaDetails.forEach((d, i) => {
        if (d?.length) {
          const label = this.labels[i]
          details.push({
            title: label,
            items: d.slice()
          })
        }
      })
      details.class = ['details']
      if (details.length === 1) {
        details.class.push('single')
      }
      return details
    }
  }
}
</script>

<style scoped>
.score-header {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #666666 !important;
}
.title-container {
  padding-top: var(--app-gutter-lg);
  padding-left: var(--app-gutter-md);
  padding-right: var(--app-gutter-md);
}
.title {
  font-size: 35px !important;
  font-family: 'Pacifico' !important;
  font-weight: 300;
  text-align: center;
}
.subtitle {
  font-size: 20px !important;
  font-family: 'DM Sans' !important;
  font-weight: 400;
  line-height: 36px;
}
.details {
  /* padding-top: var(--app-gutter-xl); */
  font-family: 'DM Sans';
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--app-gutter-md);
  /* color: #666666 !important; */
  font-size: 13px;
}
.details.single {
  padding-top: 0;
  justify-content: center;
}
.details.single .detail > *:first-child {
  display: none;
}

.detail {
  display: flex;
  flex-direction: column;
}
.detail:first-child {
  align-items: flex-start;
}
.detail:not(:first-child) {
  padding-left: var(--app-gutter-md);
}
.detail:last-child {
  align-items: flex-end;
}
.detail > *:first-child {
  font-family: 'Roboto';
  font-weight: 400;
  text-transform: uppercase;
  font-size: 10px;
  color: #cccccc;
}
.spacer {
  padding-top: var(--app-gutter-xl);
}
</style>
