<template>
  <button-menu-item
    class="switch-menu-item"
    :prepend-icon="checkIcon"
    :label="label"
    @click="toggle()"
  ></button-menu-item>
</template>

<script>
import ButtonMenuItem from '../menu-item/ButtonMenuItem'
export default {
  name: 'SwitchMenuItem',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Boolean,
    label: String
  },
  data: () => ({
    checked: false
  }),
  computed: {
    checkIcon() {
      return this.checked ? 'switch-on' : 'switch-off'
    }
  },
  watch: {
    value: {
      handler(value) {
        this.checked = value
      },
      immediate: true
    }
  },

  methods: {
    toggle() {
      this.checked = !this.checked
      this.$emit('change', this.checked)
    }
  },
  components: { ButtonMenuItem }
}
</script>

<style scoped></style>
