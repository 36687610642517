<template>
  <v-menu :close-on-content-click="autoclose" offset-x :left="left">
    <template v-slot:activator="{ on }">
      <button-menu-item
        v-on="on"
        :label="label"
        :prepend-icon="icon"
        appendIcon="chevron-right"
      />
    </template>
    <bubble-popup :left="left">
      <v-list class="pa-0" active-class="active-item">
        <slot />
      </v-list>
    </bubble-popup>
  </v-menu>
</template>

<script>
import ButtonMenuItem from '../menu-item/ButtonMenuItem'
import BubblePopup from '../misc/BubblePopup'

export default {
  name: 'FieldMenuItem',
  components: {
    ButtonMenuItem,
    BubblePopup
  },
  props: {
    label: String,
    icon: String,
    autoclose: Boolean,
    width: Number,
    fitContent: Boolean,
    left: String
  },
  data: () => ({
    slotData: null
  })
}
</script>

<style scoped>
.v-menu__content {
  contain: initial;
  overflow: initial;
  box-shadow: initial;
}
.v-list /deep/ .v-list-item::before {
  z-index: 4;
}
</style>
