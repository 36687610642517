<template>
  <v-list-item class="slider-menu-item">
    <v-list-item-icon :class="preIconClass">
      <v-btn icon v-if="iconTrigger" @click="iconTrigger">
        <app-icon lass="v-label" :value="icon"></app-icon>
      </v-btn>
      <app-icon v-else class="v-label" :value="icon"></app-icon>
    </v-list-item-icon>
    <!-- <v-list-item-content class="ml-0"> -->
    <v-slider
      class="slider flex-grow-1"
      hide-details
      v-model="sliderValue"
      :min="min"
      :max="max"
      :step="step"
      track-color="primary lighten-5"
      track-fill-color="accent"
      thumb-label
    ></v-slider>
    <!-- </v-list-item-content> -->
  </v-list-item>
</template>

<script>
import AppIcon from '../misc/AppIcon.vue'

export default {
  name: 'RangeMenuItem',
  components: {
    AppIcon
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Number,
    min: Number,
    max: Number,
    step: { type: Number, default: 1 },
    icon: String,
    iconTrigger: Function
  },
  data: () => ({
    sliderValue: NaN
  }),
  computed: {
    preIconClass() {
      const cls = ['pre-icon']
      if (this.iconTrigger) {
        cls.push('icon-button')
      }
      return cls
    }
  },
  watch: {
    // Property value
    value: {
      handler(value) {
        if (value != this.updateValue) {
          this.sliderValue = value
        }
      },
      immediate: true
    },
    // VSlider value
    sliderValue(value) {
      if (value != this.value) {
        this.$emit('change', value)
      }
    }
  },
  methods: {
    getIcon(isUp) {
      let icon = isUp ? this.iconUp : this.iconDown
      if (!icon) {
        icon = isUp ? 'plus' : 'minus'
      }
      return `mdi-${icon}`
    }
  }
}
</script>

<style scoped>
.v-list-item {
  overflow: initial;
}
.v-slider {
  margin: 0;
}
.pre-icon {
  margin: 12px 0;
}
.icon-button {
  margin: 6px -4px;
  margin-right: 28px !important;
}
</style>
