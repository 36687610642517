<template>
  <bar-menu :landscape="landscape" ref="bar">
    <select-menu-item
      :items="items"
      multiple
      :value="settings.score.screenOptions"
      @change="setSettings(['score/screenOptions', $event])"
    />
  </bar-menu>
</template>
<script>
import menuMixin from '../../mixins/menu-mixin'

import SelectMenuItem from '../menu-item/SelectMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'RenderOptionsMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SelectMenuItem
  },
  computed: {
    items() {
      return [
        { label: 'Status bar', value: 'status' },
        { label: 'Progress bar', value: 'progress' },
        { label: 'Parts bar', value: 'part' },
        { label: 'Diagrams bar', value: 'diagram' }
      ]
    }
  }
}
</script>
<style scoped></style>
