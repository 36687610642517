<template>
  <div :class="barClass" v-resize="update" v-show="!isRendering">
    <!-- MAINBAR -->
    <div class="mainbar" ref="mainbar">
      <slot />
    </div>
    <!-- SUBBAR (MENU) -->
    <v-menu
      ref="menu"
      :offset-x="landscape"
      :offset-y="!landscape"
      v-model="menuOpened"
    >
      <template v-slot:activator="{ on, attrs }">
        <bar-button
          ref="menuActivator"
          class="divider before"
          v-show="showSub"
          icon="dots-horizontal"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <div :class="`subbar ${layout}`" ref="subbar" />
    </v-menu>
  </div>
</template>

<script>
import BarButton from '../button/BarButton.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'FluidBar',
  components: { BarButton },
  props: {
    landscape: Boolean
  },
  data: () => ({
    menuOpened: true,
    showSub: true,
    layout: 'vertical',
    isRendering: false
  }),
  computed: {
    barClass() {
      const cls = ['fluid-bar']
      cls.push(this.layout + '-bar')
      return cls
    }
  },
  watch: {
    landscape() {
      this.rendering = true
      this.update()
    }
  },
  created() {
    this.update = debounce(this.debouncedUpdate, 100)
  },
  mounted() {
    this.menuOpened = false
    this.update()
  },
  methods: {
    forceUpdate() {
      this.update()
    },
    debouncedUpdate() {
      const mainbar = this.$refs.mainbar
      const subbar = this.$refs.subbar
      if (!mainbar || !subbar) return
      //
      // Eval or get slotted nodes (happens once)
      //
      let slots = []
      this.$slots.default.forEach((node) => {
        const isVisible = node.elm.style && node.elm.style.display != 'none'
        if (isVisible) {
          slots.push(node)
        }
      })
      //
      // Eval how many slots can be displayed
      //
      let slotCount = slots.length
      const size = this.landscape ? this.$el.clientHeight : this.$el.clientWidth
      this.layout = this.landscape ? 'vertical' : 'horizontal'
      const showCount = Math.min(slotCount, Math.floor((size - 48) / 48))
      if (showCount === this._showCount) {
        return
      }
      this._showCount = showCount
      //
      // Allocate node to each bar
      //
      const toMain = []
      const toSub = []
      const activator = this.$refs.menuActivator
      slots.forEach((node, i) => {
        if (i >= showCount && showCount < slotCount - 1) {
          toSub.push(node)
          node.componentInstance.subbarActivator = activator
        } else {
          toMain.push(node)
          node.componentInstance.subbarActivator = null
        }
      })
      //
      // Replace bar element children
      //
      mainbar.replaceChildren(...toMain.map((n) => n.elm))
      subbar.replaceChildren(...toSub.map((n) => n.elm))

      this.showSub = toSub.length > 0
      this.rendering = false
    }
  }
}
</script>
<style scoped>
.fluid-bar {
  width: 100%;
  height: 100%;
}
.fluid-bar,
.mainbar {
  display: flex;
}
.fluid-layout.vertical .fluid-bar,
.fluid-layout.vertical .mainbar {
  flex-direction: column;
}
/* SUBBAR */
.subbar {
  background-color: var(--app-color-layout);
  display: flex;
  flex-direction: column;
}
.subbar.vertical {
  flex-direction: row;
}
</style>
