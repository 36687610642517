<template>
  <div
    class="viewer"
    @wheel="onWheel($event, renderer)"
    @scroll="onScroll($event)"
    v-resize="onResize"
  >
    <!-- <transition name="fade"> -->
    <sheet
      id="sheet"
      ref="sheet"
      :source="source"
      :options="options"
      :head="head"
      :selection="selection"
      :locked="locked"
      :force-canvas="forceCanvas"
      :report.sync="reportInternal"
      :rendering.sync="renderingInternal"
      :verbose="verbose"
      :compact="compact"
      :lock-size="lockSize"
      @selection="onSelection($event)"
      v-on="$listeners"
    >
      <template v-slot:header><slot name="header"></slot></template>
      <template v-slot:footer><slot name="footer"></slot></template>
      <template v-slot:empty><slot name="empty"></slot></template>
    </sheet>
    <!-- </transition> -->
    <slot name="status"></slot>
  </div>
</template>
<script>
import Sheet from './Sheet.vue'
import throttle from 'lodash/throttle'

export default {
  name: 'Viewer',
  components: { Sheet },
  props: {
    verbose: Boolean,
    source: String,
    options: Array,
    head: Number,
    selection: Object,
    forceCanvas: Boolean,
    report: Object,
    rendering: Boolean,
    locked: Boolean,
    compact: Boolean,
    progress: Number,
    lockSize: Boolean,
    scrollTrigger: String
  },
  data: () => ({
    reportInternal: null,
    renderingInternal: false,
    scrollBehavior: 'instant',
    scrollTop: 0
  }),
  computed: {
    renderer() {
      const option = this.options.find((e) => e.name == 'renderer')
      return (option && option.value) || 'lyric'
    }
  },
  watch: {
    reportInternal(value) {
      this.$emit('update:report', value)
    },
    renderingInternal(value) {
      this.$emit('update:rendering', value)
    },
    renderer() {
      this.scrollBehavior = 'instant'
    },
    progress() {
      if (this.scrollTrigger === 'progress') {
        this.debouncedScrollForProgress()
      }
    }
  },
  mounted() {
    this.debouncedScrollTo = throttle(this.scrollTo, 600, {
      trailing: true,
      leading: true
    })
    this.debouncedScrollForProgress = throttle(this.scrollForProgress, 15)
  },
  methods: {
    forceUpdate(...args) {
      this.$refs.sheet.forceUpdate(...args)
    },
    updateSelection() {
      this.$refs.sheet.updateSelection()
    },

    ignoreNextScroll(val) {
      this._ignoreNextScroll = val
    },

    scrollTo(elt) {
      if (this._ignoreNextScroll) {
        this._ignoreNextScroll--
        return
      }
      const eltBound = elt.getBoundingClientRect()
      let thisBound = this.$el.getBoundingClientRect()
      const elX = eltBound.left + eltBound.width / 2 - thisBound.left
      const elY = eltBound.top + eltBound.height / 2 - thisBound.top
      const left = elX - thisBound.width / 2
      const top = elY - thisBound.height / 2
      this.$el.scrollBy({ top, left, behavior: 'smooth' })
      this.scrollBehavior = 'smooth'
    },

    scrollForProgress() {
      const scrollHeight = this.$el.scrollHeight - this.$el.clientHeight / 2
      const scrollTop = Math.round(this.progress * scrollHeight)
      const diff = Math.abs(scrollTop - this.$el.scrollTop)
      if (diff >= 1) {
        this.$el.scrollTo({ top: scrollTop, behavior: 'instant' })
      }
    },

    onWheel(event, renderer) {
      if (renderer != 'card') return
      this.$el.scrollTo(this.$el.scrollLeft + event.deltaY, 0)
    },

    onScroll(event) {
      this.$emit('scroll', event)
    },

    onResize() {
      this.scrollBehavior = 'instant'
    },

    onSelection(event) {
      if (this.scrollTrigger === 'selection') {
        this.debouncedScrollTo(event.scrollTarget)
      }
    },

    updateAvailableWidth() {
      this.$refs.sheet.updateAvailableWidth()
    }
  }
}
</script>

<style scoped>
.viewer {
  overflow: auto;
  position: relative;
}
.fade-enter-active {
  transition: opacity 0.5s;
  /* transition-delay: 0.1s; */
}
.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
</style>
