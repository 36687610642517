<template>
  <v-list-item
    class="button-menu-item"
    link
    v-on="$listeners"
    v-on:click="onClick($event)"
    :to="to"
  >
    <v-list-item-icon class="pre-icon" v-if="prependIcon">
      <app-icon class="v-label" :value="prependIcon"></app-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <slot></slot>
      <v-label>{{ label }}</v-label>
    </v-list-item-content>
    <v-list-item-icon v-if="appendIcon">
      <app-icon class="v-label" :value="appendIcon"></app-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import AppIcon from '../misc/AppIcon.vue'
export default {
  name: 'ButtonMenuItem',
  props: {
    label: String,
    to: String,
    disabled: Boolean,
    trigger: Function,
    prependIcon: String,
    appendIcon: String
  },
  methods: {
    onClick: function () {
      const slotItem = this.$slots.default && this.$slots.default[0]
      if (slotItem) {
        if (slotItem.componentOptions?.tag?.endsWith('dialog')) {
          const dialog = slotItem.child
          setTimeout(dialog.open, 10)
        }
      } else if (this.trigger) this.trigger()
    }
  },
  components: { AppIcon }
}
</script>
<style scoped>
.v-list-item__icon {
  margin: 12px 0;
}
</style>
