<template>
  <div class="part-bar">
    <v-btn
      text
      v-for="(part, partIndex) in parts"
      :key="partIndex"
      class="part-tab"
      :disabled="isPeer"
      @click="goto({ progress: part.start })"
    >
      <div :class="getPartClass(part, progress)">{{ part.label }}</div>
      <!-- <div class="progress" :style="getProgress(part, progress)" /> -->
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PartBar',

  computed: {
    ...mapGetters('score', ['report', 'tags']),
    ...mapGetters('player', ['start', 'progress', 'tempoRatio']),

    isPeer() {
      return this.$route.name?.startsWith('peer')
    },

    percent() {
      if (!this.report) return
      const report = this.report
      const total = Math.round(report.duration.time / this.tempoRatio)
      const current = this.progress
      return (current / total) * 100
    },

    progressStyle() {
      return `width:${this.percent}%`
    },

    parts() {
      if (!this.report) return
      const report = this.report
      const events = report.events()
      const total = Math.round(report.duration.time / this.tempoRatio)
      const parts = []
      events.forEach((event) => {
        event.forEach((context) => {
          if (context.name === 'part' && context.raw != '-') {
            const start = Math.round(event.position.time / this.tempoRatio)
            parts.push({ label: context.raw, start })
          }
        })
      })
      if (parts.length && parts[0].start > 0) {
        parts.unshift({ label: '', start: 0 })
      }
      parts.forEach((part, partIndex) => {
        const next = parts[partIndex + 1]
        const end = next ? next.start : total
        const duration = end - part.start
        // const flex = duration / total
        part.duration = duration
        part.end = end
        part.style = `flex: 1`
      })
      return parts
    }
  },
  methods: {
    ...mapActions('player', ['goto']),

    getPartClass(part, progress) {
      const cls = ['part-name']
      if (progress < part.end && progress >= part.start) cls.push('selected')
      return cls
    }
  }
}
</script>

<style scoped>
.part-bar {
  background: var(--app-color-layout);
  display: flex;
  border-top: solid 1px var(--app-color-line);
  align-items: center;
  height: 36px;
}
.part-tab:not(:last-child) {
  border-right: solid 1px var(--app-color-line);
}
.part-tab {
  position: relative;
  z-index: 2;
  font-size: small !important;
  text-align: center;
  text-transform: initial;
  font-weight: initial;
  height: 100% !important;
  border-radius: initial;
  padding: 0 !important;
  padding-top: 2px !important;
  display: inline-block;
  min-width: 0 !important;
  flex: 1;
}
.part-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 2px;
}
.part-name.selected {
  color: var(--v-accent-base);
}
</style>
