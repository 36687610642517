<template>
  <bar-menu :landscape="landscape" ref="bar">
    <select-menu-item
      :items="rendererItems"
      :value="settings.score.renderer"
      @change="rendererChangeHandler($event)"
    />
    <v-divider />
  </bar-menu>
</template>
<script>
import menuMixin from '../../mixins/menu-mixin'

import SelectMenuItem from '../menu-item/SelectMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'RenderMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SelectMenuItem
  },
  data: () => ({
    rendererItems: [
      { label: 'Lyrics', icon: 'render-lyric', value: 'lyric' },
      { label: 'Chart', icon: 'render-chart', value: 'chart' },
      { label: 'Notes', icon: 'render-staff', value: 'staff' }
    ]
  }),
  methods: {
    rendererChangeHandler(value) {
      this.setSettings(['score/renderer', value])
      this.$refs.bar.hide()
    }
  }
}
</script>
<style scoped></style>
