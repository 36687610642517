<template>
  <bar-menu :landscape="landscape" ref="bar">
    <select-menu-item
      :items="items"
      multiple
      :value="settings.score.renderOptions"
      @change="setSettings(['score/renderOptions', $event])"
    />
  </bar-menu>
</template>
<script>
import { mapGetters } from 'vuex'
import menuMixin from '../../mixins/menu-mixin'

import SelectMenuItem from '../menu-item/SelectMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'RenderOptionsMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SelectMenuItem
  },
  computed: {
    ...mapGetters('score', ['renderer']),

    items() {
      const isLyric = this.renderer === 'lyric'
      const isChart = this.renderer === 'chart'
      const isStaff = !isLyric && !isChart
      const items = []
      if (!isChart) {
        items.push(
          { label: 'Show harmony', value: 'harmony' },
          { label: 'Show lyrics', value: 'lyric' }
        )
      }
      if (!isStaff) {
        items.push({ label: 'Show metric', value: 'metric' })
      }
      items.push(
        { label: 'Show parts', value: 'part' },
        { label: 'Show headers', value: 'header' }
      )
      return items
    }
  }
}
</script>
<style scoped></style>
