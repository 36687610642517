<template>
  <bar-menu :activator="activator" :landscape="landscape">
    <select-menu-item
      :items="layoutItems"
      :value="value"
      @change="setScoreSettings(['layout', $event])"
    />
    <v-divider />
    <sub-menu-item label="Spacing" v-if="isStaff">
      <select-menu-item
        :items="spacingItems"
        :value="score.settings.spacing"
        @change="setScoreSettings(['spacing', $event])"
      />
    </sub-menu-item>
  </bar-menu>
</template>

<script>
import menuMixin from '../../mixins/menu-mixin'

import SelectMenuItem from '../menu-item/SelectMenuItem.vue'
import SubMenuItem from '../menu-item/SubMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'LayoutMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SelectMenuItem,
    SubMenuItem
  },
  props: { activator: String, landscape: Boolean },
  data: () => ({
    spacingItems: [
      { label: 'Dense', value: 'dense', icon: 'spacing-dense' },
      { label: 'Medium', value: 'default', icon: 'spacing-default' },
      { label: 'Strict', value: 'strict', icon: 'spacing-strict' }
    ]
  }),
  computed: {
    isStaff() {
      return this.scoreSettings.renderer === 'staff'
    },

    layoutItems() {
      const items = [
        { label: 'Wrap', value: 'wrap', icon: 'wrap' },
        { label: '3 measures', value: '3-cols', icon: 'layout-3-cols' },
        { label: '4 measures', value: '4-cols', icon: 'layout-4-cols' },
        { label: '5 measures', value: '5-cols', icon: 'layout-5-cols' },
        { label: '6 measures', value: '6-cols', icon: 'layout-6-cols' }
      ]
      if (this.isStaff) {
        items.push({
          label: 'Breakline',
          value: 'breakline',
          icon: 'format-pilcrow'
        })
      }
      return items
    },

    value() {
      const value = this.score.settings.layout
      const isBreakLine = value === 'breakline'
      return !this.isStaff && isBreakLine ? 'wrap' : value
    }
  }
}
</script>
<style scoped>
.group-layout {
  border-bottom: solid 1px var(--app-color-line);
}
</style>
