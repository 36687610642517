<template>
  <bar-menu :landscape="landscape" autoclose>
    <select-menu-item
      :items="computedItems"
      :value="computedValue"
      @change="setSettings(['player/highlight', $event])"
    />
  </bar-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import menuMixin from '../../mixins/menu-mixin'

import SelectMenuItem from '../menu-item/SelectMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'

export default {
  name: 'HighlightMenu',
  mixins: [menuMixin],
  components: {
    BarMenu,
    SelectMenuItem
  },
  props: { activator: String, landscape: Boolean },
  computed: {
    ...mapGetters('score', ['report']),

    computedValue() {
      if (!this.settings || !this.report) return 'none'
      let hightlight = this.settings.player.highlight
      const renderer = this.settings.score.renderer
      const hasMetric = this.report.hasMetric
      if (renderer === 'chart' && hightlight === 'line') hightlight = 'measure'
      if (renderer === 'chart' && hightlight === 'event') hightlight = 'beat'
      if (!hasMetric && hightlight != 'none') hightlight = 'line'
      return hightlight
    },

    computedItems() {
      if (!this.settings || !this.report) return []
      const r = []

      r.push({ label: 'No highlight', value: 'none', icon: 'highlight-none' })

      const renderer = this.settings.score.renderer
      if (renderer === 'lyric') {
        r.push({
          label: 'Highlight line',
          value: 'line',
          icon: 'highlight-row'
        })
      }

      if (this.report.hasMetric) {
        r.push(
          {
            label: 'Highlight measure',
            value: 'measure',
            icon: 'highlight-measure'
          },
          { label: 'Highlight beat', value: 'beat', icon: 'highlight-beat' }
        )

        if (renderer === 'lyric' || renderer === 'staff') {
          r.push({
            label: 'Highlight event',
            value: 'event',
            icon: 'highlight-event'
          })
        }
      }
      return r
    }
  }
}
</script>
<style scoped></style>
