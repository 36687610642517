<template>
  <bar-menu :landscape="landscape">
    <div class="pad">
      <tile-button
        icon="chevron-double-left"
        class="divider"
        @click="update(-50)"
      />
      <tile-button icon="chevron-left" class="divider" @click="update(-1)" />
      <tile-button icon="restore" class="divider" @click="restoreTempo()" />
      <tile-button icon="chevron-right" class="divider" @click="update(1)" />
      <tile-button icon="chevron-double-right" @click="update(50)" />
    </div>
    <!-- VALUES -->
    <div class="values" v-if="hasMetric">
      <div class="bpm">{{ bpm }}<span class="unit">BPM</span></div>
      <div class="percent">{{ percent }}% - {{ time }}</div>
    </div>
    <div class="values" v-else>
      <div class="bpm">{{ percent }}<span class="unit">%</span></div>
      <div class="percent">{{ time }}</div>
    </div>

    <div v-if="hasMetric">
      <v-divider />
      <switch-menu-item
        v-if="!isEditor"
        label="Display metronome"
        :value="settings.metronome.display != 'none'"
        @change="toggleDisplay($event)"
      />
      <slider-menu-item
        :icon-trigger="muteTempo"
        icon="tempo-volume"
        :value="settings.metronome.volume"
        @change="setSettings(['metronome/volume', $event])"
        :max="100"
      />
      <v-divider />
      <slider-menu-item
        :icon-trigger="cancelCountdown"
        icon="timer-outline"
        :value="settings.score.countdown"
        @change="setSettings(['score/countdown', $event])"
        :max="16"
      />
    </div>
  </bar-menu>
</template>

<script>
import { mapGetters } from 'vuex'

import menuMixin from '../../mixins/menu-mixin'
import tempoMixin from '../../mixins/tempo-mixin'

import debounce from 'lodash/debounce'
import { formatTime } from '../../utils/format-utils'

import SwitchMenuItem from '../menu-item/SwitchMenuItem.vue'
import BarMenu from '../menu/BarMenu.vue'
import TileButton from '../button/TileButton.vue'
import SliderMenuItem from '../menu-item/SliderMenuItem.vue'
export default {
  name: 'TempoMenu',
  mixins: [menuMixin, tempoMixin],
  components: {
    TileButton,
    BarMenu,
    SwitchMenuItem,
    SliderMenuItem
  },
  data: () => ({
    enabled: false,
    bpm: 120,
    ratio: 0,
    lastRatio: 0,
    duration: 0
  }),
  watch: {
    'headEvent.tempo'(value) {
      this.bpm = value
    },
    'scoreSettings.tempo'(value) {
      this.ratio = value
    },
    'report.duration.time'(value) {
      this.duration = value
    }
  },
  computed: {
    ...mapGetters('player', ['headEvent']),

    percent() {
      return Math.round(this.ratio * 100)
    },

    time() {
      return formatTime(this.duration)
    },

    hasMetric() {
      return this.report?.hasMetric
    }
  },
  mounted() {
    this.debouncedUpdateTempo = debounce(this.updateTempo, 250)
    this.bpm = this.headEvent?.tempo || 120
    this.ratio = this.scoreSettings?.tempo || 1
    this.duration = this.report?.duration.time || 0
  },
  methods: {
    toggleDisplay(value) {
      const display = value ? 'default' : 'none'
      this.setSettings(['metronome/display', display])
    },

    restoreTempo() {
      this.update(this.headEvent.tempo - this.bpm)
    },

    update(val) {
      const newBpm = this.bpm + val
      const baseBpm = this.headEvent.tempo
      const newRatio = newBpm / baseBpm
      this.duration *= this.ratio / newRatio
      this.bpm = newBpm
      this.ratio = newRatio
      this.debouncedUpdateTempo(newRatio)
    },

    muteTempo() {
      this.setSettings(['metronome/volume', 0])
    },

    cancelCountdown() {
      this.setSettings(['score/countdown', 0])
    }
  }
}
</script>
<style scoped>
.pad {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--app-color-line);
}

.values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  padding: var(--app-gutter-md);
}
.bpm {
  font-size: x-large;
  font-weight: bold;
}
.bpm .unit {
  font-size: small;
  margin-left: var(--app-gutter-xs);
  margin-top: -5px;
}
.percent {
  font-size: small;
}

.switch-menu-item {
  border-bottom: solid 1px var(--app-color-line);
}
</style>
