<template>
  <v-list-item-group
    :class="itemClass"
    :multiple="multiple"
    v-model="selection"
    @change="updateValue($event)"
  >
    <v-list-item v-for="(item, index) in items" :key="index" link>
      <template v-slot:default="{ toggle, active }">
        <v-list-item-action v-if="item.icon || multiple">
          <app-icon @click="toggle" :value="getIcon(item, active, multiple)" />
        </v-list-item-action>
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </template>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import selectItemMixin from '../../mixins/selectItemMixin'

import AppIcon from '../misc/AppIcon'

export default {
  name: 'SelectMenuItem',
  mixins: [selectItemMixin],
  components: { AppIcon },
  computed: {
    itemClass() {
      const cls = ['select-menu-item']
      if (this.multiple) cls.push('multiple')
      return cls
    }
  },
  methods: {
    getIcon(item, active, multiple) {
      if (multiple) {
        return active ? 'switch-on' : 'switch-off'
      } else {
        return item.icon
      }
    }
  }
}
</script>
<style scoped>
.multiple .v-list-item--active::before {
  opacity: 0;
}
</style>
