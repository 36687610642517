<template>
  <v-footer app padless :height="footerHeight">
    <part-bar v-if="showPartBar" />
    <progress-bar v-if="showProgressBar" />
    <status-bar v-if="showStatusBar" />
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

import StatusBar from '../toolbar/StatusBar.vue'
import PartBar from '../toolbar/PartBar.vue'
import ProgressBar from '../toolbar/ProgressBar.vue'

export default {
  name: 'ScoreFooter',
  components: {
    ProgressBar,
    StatusBar,
    PartBar
  },
  computed: {
    ...mapGetters('score', ['report', 'screenOptions']),

    showPlayButton() {
      const isEditor = this.$route.params.contextId === 'editor'
      const isPeer = this.$route.name?.startsWith('peer')
      return !isEditor && !isPeer
    },

    showProgressBar() {
      return this.screenOptions.indexOf('progress') > -1
    },

    showPartBar() {
      const hasOptions = this.screenOptions.indexOf('part') > -1
      return this.report?.hasPart && hasOptions
    },

    showStatusBar() {
      return this.screenOptions.indexOf('status') > -1
    },

    footerHeight() {
      let height = 0
      if (this.showProgressBar) height += 36
      if (this.showPartBar) height += 36
      if (this.showStatusBar) height += 24
      return height
    }
  },
  methods: {
    onTogglePlay() {
      if (this.loading) return
      if (this.playing || this.delaying) {
        this.stop()
      } else {
        if (this.countdown && this.report.hasMetric) {
          this.playWithDelay(this.countdown)
        } else {
          this.play()
        }
      }
    }
  }
}
</script>

<style scoped>
.v-footer {
  align-items: stretch;
  flex-direction: column;
}
.v-footer > * {
  width: 100%;
}
</style>
