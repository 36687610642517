<template>
  <div class="progress-bar">
    <div
      class="slider"
      :class="sliderClass"
      ref="slider"
      @mousedown="sliderMouseDownHandler"
    >
      <div class="track" :style="trackStyle" />
      <div
        class="thumb elevation-3"
        :style="thumbStyle"
        @mousedown.stop="thumbMouseDownHandler"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import throttle from 'lodash/throttle'
export default {
  name: 'ProgressBar',
  data: () => ({
    percent: 0,
    downInfo: null
  }),
  computed: {
    ...mapGetters('score', ['report']),
    ...mapGetters('player', ['progress', 'tempoRatio', 'playing']),

    isPeer() {
      return this.$route.name?.startsWith('peer')
    },
    totalProgress() {
      if (!this.report) return 0
      const report = this.report
      return Math.round(report.duration.time / this.tempoRatio)
    },

    sliderClass() {
      return {
        isDown: Boolean(this.downInfo)
      }
    },

    trackStyle() {
      return `width:${this.percent}%`
    },

    thumbStyle() {
      return `left:${this.percent}%`
    }
  },

  watch: {
    progress(value) {
      if (!this.report) return 0
      const total = this.totalProgress
      this.percent = (value / total) * 100
    }
  },
  created() {
    this.debouncedUpdate = throttle(
      (ratio) => {
        this.update(ratio)
      },
      50,
      { leading: true, trailing: true }
    )
  },
  destroyed() {
    this.removeListeners()
  },

  methods: {
    ...mapActions('player', ['goto', 'play', 'stop']),

    update(ratio) {
      ratio = Math.max(0, Math.min(1, ratio))
      const progress = ratio * this.totalProgress
      this.goto({ progress })
    },

    removeListeners() {
      if (!this.downInfo) return
      window.removeEventListener('mousemove', this.mouseMoveHandler)
      window.removeEventListener('mouseup', this.mouseUpHandler)
      if (this.downInfo.playing && !this.playing) {
        this.play()
      }
      this.downInfo = null
    },

    thumbMouseDownHandler(event) {
      window.addEventListener('mousemove', this.mouseMoveHandler)
      window.addEventListener('mouseup', this.mouseUpHandler)
      this.downInfo = {
        playing: this.playing,
        start: event.x,
        max: this.$refs.slider.getBoundingClientRect().width,
        from: this.percent
      }
      if (this.playing) {
        this.stop()
      }
    },

    mouseUpHandler() {
      this.removeListeners()
    },

    mouseMoveHandler(event) {
      const diff = event.x - this.downInfo.start
      const ratio = this.downInfo.from / 100 + diff / this.downInfo.max
      this.debouncedUpdate(ratio)
    },

    sliderMouseDownHandler(event) {
      const sliderBound = this.$refs.slider.getBoundingClientRect()
      const local = event.clientX - sliderBound.left
      const ratio = local / sliderBound.width
      this.debouncedUpdate(ratio)
      this.$nextTick(() => this.thumbMouseDownHandler(event))
    }
  }
}
</script>

<style scoped>
.progress-bar {
  --bar-size: 36px;
  --track-size: 6px;
  height: var(--bar-size);
  border-top: solid 1px var(--app-color-line);
  /* display: flex; */
  /* align-items: center; */
}
.slider {
  position: relative;
  margin: 0 calc(var(--bar-size) / 2);
  height: 100%;
}
.slider:before {
  content: ' ';
  background: #cccccc;
  width: 100%;
}
.slider:before,
.track {
  position: absolute;
  top: calc(50% - var(--track-size) / 2);
  height: var(--track-size);
  border-radius: calc(var(--track-size) / 2);
}
.track {
  background: var(--v-accent-base);
}
.thumb {
  position: absolute;
  background: var(--app-color-layout);
  border-radius: var(--bar-size);
  top: 0;
  width: var(--bar-size);
  height: 100%;
  margin-left: calc(var(--bar-size) / -2);
  transform: scale(0.75);
  cursor: pointer;
}
/* .slider:not(.isDown) .track {
  transition: width 0.25s ease-in-out;
}
.slider:not(.isDown) .thumb {
  transition: left 0.25s ease-in-out;
} */
</style>
