import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('player', ['progress']),
    ...mapGetters('score', ['scoreSettings'])
  },
  methods: {
    ...mapActions('player', ['goto']),
    ...mapActions('score', ['setScoreSettings']),

    async updateTempo(value) {
      const ratio = this.scoreSettings.tempo / value
      const progress = this.progress * ratio
      this.goto({ progress, noUpdate: true })
      await this.setScoreSettings(['tempo', value])
    }
  }
}
