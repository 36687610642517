<template>
  <div class="sheet-footer">
    <div class="copyrights">
      <div
        class="copyright"
        v-for="copyright in copyrights"
        :key="copyright.index"
      >
        {{ copyright.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SheetFooter',
  data: () => ({}),
  computed: {
    ...mapGetters('score', ['score']),

    scoreMeta() {
      return (this.score && this.score?.meta) || {}
    },

    copyrights() {
      let copyrights = []
      if (this.scoreMeta?.copyright?.length) {
        copyrights = this.scoreMeta.copyright.map((label, index) => ({
          label,
          index
        }))
      }
      return copyrights
    }
  }
}
</script>

<style scoped>
.sheet-footer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.copyrights {
  padding-top: var(--app-gutter-lg);
}
.copyright:first-child:before {
  content: '© ';
}
.copyright {
  font-style: italic;
  color: #cccccc;
  font-size: 11px;
  font-family: 'Roboto' !important;
}
</style>