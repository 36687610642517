<template>
  <div class="score-bar">
    <score-tags :tags="tags" />
    <div
      class="detail"
      v-for="(detail, detailIndex) in details"
      :key="detailIndex"
    >
      {{ detail }}
    </div>
    <v-spacer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatTime } from '../../utils/format-utils'

import ScoreTags from '../score/ScoreTags.vue'
export default {
  name: 'StatusBar',
  components: { ScoreTags },
  computed: {
    ...mapGetters('score', ['report', 'tags']),
    ...mapGetters('player', ['headEvent', 'progress', 'tempoRatio']),

    details() {
      const r = []
      if (!this.headEvent || !this.report) return r
      const report = this.report
      const total = Math.round(report.duration.time / this.tempoRatio)
      const current = this.progress
      const currentProgress = formatTime(current)
      const totalProgress = formatTime(total)
      const percent = Math.round((current / total) * 100)
      const tempo = Math.round(this.tempoRatio * this.headEvent.tempo)

      r.push(`Progress: ${currentProgress}/${totalProgress} (${percent}%)`)

      if (report.hasMetric && this.$vuetify.breakpoint.smAndUp) {
        const measureCount = this.report.duration.fragment
        const measureIndex = this.headEvent.fragmentIndex + 1
        r.push(`Measures: ${measureIndex}/${measureCount}`)

        const beatCount = this.report.duration.beat
        const beatIndex = this.headEvent.beatIndex + 1
        r.push(`Beat: ${beatIndex}/${beatCount}`)
      }

      r.push(`Tempo: ${tempo}`)

      return r
    }
  }
}
</script>

<style scoped>
.score-bar {
  background: var(--app-color-layout);
  display: flex;
  border-top: solid 1px var(--app-color-line);
  align-items: center;
  padding: 0 var(--app-gutter-sm);
  height: 24px;
}

.score-tags > * {
  --score-tag-padding-y: 0;
}

/* PROGRESS */

.detail {
  white-space: nowrap;
  display: flex;
  font-size: small;
  border-right: solid 1px var(--app-color-line);
  align-self: stretch;
  align-items: center;
}
.detail:nth-child(2) {
  margin-left: var(--app-gutter-sm);
  padding-left: var(--app-gutter-sm);
  border-left: solid 1px var(--app-color-line);
}
.detail:not(:last-child) {
  margin-right: var(--app-gutter-sm);
  padding-right: var(--app-gutter-sm);
}
</style>
