import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    dragName: null,
    dragIcon: null
  }),

  computed: {
    ...mapState('drive', ['draggedItem'])
  },

  methods: {
    ...mapMutations('drive', ['setDraggedItem']),
    ...mapActions('drive', ['move']),

    dragstart(item, event) {
      if (!item) return
      this.dragName = item.name
      this.dragIcon = this.getItemIcon(item)
      const dummy = this.$refs.dummy
      const dt = event.dataTransfer
      this.setDraggedItem(item)
      dt.setDragImage(dummy, 25, 25)
      event.currentTarget.classList.add('dragged')
    },
    dragover(item, event) {
      if (!item) return
      const source = this.draggedItem
      const destIsFolder = item.isFolder
      const destNotSource = item.id != source.id
      const sourceNotInDest = source.parent != item.id
      if (destIsFolder && destNotSource && sourceNotInDest) {
        event.preventDefault()
        event.currentTarget.classList.add('dragover')
      }
      event.dataTransfer.dropEffect = 'move'
      return false
    },
    dragleave(item, event) {
      event.currentTarget.classList.remove('dragover')
    },
    dragdrop(item, event) {
      const sourceId = this.draggedItem.id
      const destinationId = item.id
      event.currentTarget.classList.remove('dragover')
      event.preventDefault()
      this.move({ sourceId, destinationId })
    },
    dragend(item, event) {
      this.setDraggedItem(null)
      event.currentTarget.classList.remove('dragged')
    }
  }
}
