<template>
  <div>
    <v-menu
      v-model="menuOpened"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <component :is="type" :item="item" @subclick="close()" />
    </v-menu>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'ContextMenu',
  components: {
    drive: () => import('../menu/DriveMenu.vue'),
    score: () => import('../menu/ScoreMenu.vue')
  },
  props: { type: String },
  data: () => ({
    menuOpened: false,
    item: null,
    x: 0,
    y: 0
  }),

  methods: {
    ...mapMutations('app', ['setContextMenu']),

    open(item, event) {
      event.preventDefault()
      this.item = item
      this.menuOpened = false
      this.x = event.clientX
      this.y = event.clientY
      this.$nextTick(() => {
        this.menuOpened = true
        this.setContextMenu(this.close)
      })
    },

    close() {
      if (!this.menuOpened) return
      this.menuOpened = false
      this.setContextMenu()
    }
  }
}
</script>
<style scoped></style>
